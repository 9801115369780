<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-img :src="mediaURL + storage" height="80vh" contain>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#FDCE48"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pa-2>
      <v-flex xs12 sm6 md4 xl4>
        <v-btn
          block
          tile
          color="#FDCE48"
          light
          :ripple="false"
          depressed
          @click="closeDialoge"
          class="itemValue"
        >
          Close
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        imageViewer: false,
      });
    },
  },
};
</script>