import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    userData: {},
    menus: [],
    initial: "",
  },
  mutations: {
    loginUser(state, item) {
      localStorage.setItem("token", item);
      state.isLoggedIn = true;
      if (state.initial && state.userType == "admin") {
        router.push(state.initial);
        // router.push("/Admins");
      } else router.push(state.initial);

      // else if (state.userType == "subadmin") {
      //   router.push("/");
      // } else router.push("/products");
    },
    logoutUser(state) {
      axios({
        method: "GET",
        url: "/user/logout",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.status) {
          state.userType = null;
          state.isLoggedIn = false;
          state.userData = {};
          state.initial = "";
          localStorage.removeItem("userType");
          localStorage.removeItem("token");
          router.push("/Login");
        }
      });
    },
    menu(state, item) {
      state.menus = item;
      if (item[0]._id !== "6461d8092a86772eed164094") {
        state.initial = item[0].subMenu[0].route;
        // console.log("Ok")
      } else {
        state.initial = "/Admins";
        // console.log("bad")
      }
    },
    userData(state, item) {
      state.userData = item;
    },
    userType(state, item) {
      localStorage.setItem("userType", item);
      state.userType = item;
    },
    sessionOut(state) {
      localStorage.removeItem("token");
      state.isLoggedIn = false;
      router.push("/Login");
    },
  },
});
