var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"appNavbar"}},[(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#202020"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","py-2":"","text-uppercase":"","align-self-center":""}},_vm._l((_vm.appMenu),function(main){return _c('v-list',{key:main._id,attrs:{"dark":"","dense":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-2":""}},[(main._id !== '60e7e737933dd31d84ee3fff')?_c('v-list-group',{attrs:{"no-action":"","value":false,"active-class":`white--text`},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticStyle:{"font-size":"13px","letter-spacing":"3px","cursor":"pointer","font-family":"poppinsthin"}},[_vm._v(_vm._s(main.name))])]},proxy:true}],null,true)},[_c('v-flex',{attrs:{"xs12":"","py-2":"","px-4":""}},[_c('div',{staticStyle:{"border-bottom":"1px solid #ffffff25"}})]),_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,attrs:{"xs12":"","text-left":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"13px","color":"#ffffff","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == sub.route
                                ? {
                                    'font-family': 'poppinsbold',
                                  }
                                : {
                                    'font-family': 'poppinsthin',
                                  })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":"","py-2":"","px-4":""}},[_c('div',{staticStyle:{"border-bottom":"1px solid #ffffff25"}})])]})],2):_c('v-list',{attrs:{"dark":"","dense":""}},[_vm._l((_vm.admin),function(item,i){return [_c('v-flex',{key:i,attrs:{"xs12":"","pl-4":"","align-self-center":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":item.route}},[_c('span',{staticStyle:{"font-size":"13px","color":"#ffffff","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == item.route
                                ? {
                                    'font-family': 'poppinsbold',
                                  }
                                : {
                                    'font-family': 'poppinsthin',
                                  })},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)],1)]})],2)],1)],1)],1)}),1)],1)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up",attrs:{"app":"","dark":"","color":"white","dense":"","flat":"","height":"60px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"#68D389"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-start":"","py-5":""}},[_c('v-flex',{attrs:{"xs3":"","sm2":"","pt-1":""}},[_c('v-img',{attrs:{"src":require("./../../assets/wayanadLogo.png"),"height":"50px","contain":""}})],1),_c('v-flex',{attrs:{"xs9":"","sm10":"","md8":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"14px","cursor":"pointer","color":"black"}},[_vm._v(" Management Information "),_c('br'),_vm._v(" System ")])])],1)],1)],1),(_vm.appType == 'Client')?_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",nativeOn:{"click":function($event){_vm.sideNav2 = !_vm.sideNav2}}},[_c('v-icon',{attrs:{"color":"#ff0000"}},[_vm._v("mdi-account")])],1):_vm._e()],1),_c('v-app-bar',{staticClass:"hidden-md-and-down",attrs:{"color":"white","elevation":"0","height":"70px"}},[_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{attrs:{"xs1":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require("./../../assets/wayanadLogo.png"),"height":"60px","contain":""}})],1)],1),_c('v-flex',{attrs:{"xs10":"","sm7":"","md10":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"19px","cursor":"pointer","color":"black"}},[_vm._v(" Management Information System ")])]),_c('v-spacer'),_c('v-flex',{attrs:{"xs6":"","lg1":"","xl1":"","px-4":"","text-center":"","align-self-center":""}},[_c('AccountMenu')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }