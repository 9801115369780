<template>
  <div id="appNavbar">
    <ServerError v-if="ServerError" />
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#202020">
      <v-layout wrap>
        <v-flex xs12 text-left py-2 text-uppercase align-self-center>
          <v-list dark dense v-for="main in appMenu" :key="main._id">
            <v-layout wrap>
              <v-flex xs12 py-2>
                <v-list-group
                  v-if="main._id !== '60e7e737933dd31d84ee3fff'"
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >{{ main.name }}</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="sub in main.subMenu">
                    <v-flex xs12 text-left pl-4 :key="sub._id">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="sub.route">
                            <span
                              :style="
                                $route.path == sub.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ sub.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="sub._d">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
                <!-- <v-list-group v-else> -->
                <v-list dark dense v-else>
                  <template v-for="(item, i) in admin">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex> -->
                  </template>
                </v-list>
              </v-flex>
            </v-layout>
            <!-- </v-list-group> -->
          </v-list>
        </v-flex>
      </v-layout>

      <!-- START--STATIC SUBLEVELS V1 -->

      <!-- <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >DASHBOARD</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in dash">
                    <v-flex xs12 text-left pl-4 :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >REPORTS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in reports">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <template v-for="(item, i) in admin">
                  <v-flex xs12 pl-4 align-self-center text-left :key="i">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <router-link :to="item.route">
                          <span
                            :style="
                              $route.path == item.route
                                ? {
                                    'font-family': 'poppinsbold',
                                  }
                                : {
                                    'font-family': 'poppinsthin',
                                  }
                            "
                            style="
                              font-size: 13px;
                              color: #ffffff;
                              letter-spacing: 3px;
                              cursor: pointer;
                            "
                          >
                            {{ item.name }}
                          </span>
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-list>
            </v-flex>
          </v-layout>

          
          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >PROGRAMS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in programs">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>
          
          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >ACCOMODATIONS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in accommodations">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >PRODUCTS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in products">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          
          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >WEBSITE CONTENT</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in website">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout> -->
      <!-- END--STATIC SUBLEVELS V1 -->

      <!-- <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in appNavItems"
          :key="i"
          text-center
          pa-2
          pl-4
          text-uppercase
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12>
              <router-link :to="item.route">
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'poppinsbold',
                        }
                      : {
                          'font-family': 'poppinsthin',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: #ffffff;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </router-link>
            </v-flex>
            <v-flex xs12 pt-2>
              <v-divider color="#fff"></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <span
                @click="appLogout"
                style="
                  font-family: poppinsthin;
                  font-size: 15px;
                  color: #ffffff;
                  letter-spacing: 3px;
                  cursor: pointer;
                "
              >
                Logout
              </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <v-divider color="#fff"></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout> -->
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="white"
      dense
      flat
      height="60px"
      class="hidden-lg-and-up"
    >
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="#68D389">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-layout wrap justify-start py-5>
            <v-flex xs3 sm2 pt-1>
              <!-- <span
                style="
                  font-family: poppinsbold;
                  font-size: 20px;
                  cursor: pointer;
                  color: #545454;
                "
              >
                Paramabikulam Tiger Reserve
              </span> -->
              <v-img src="./../../assets/wayanadLogo.png" height="50px" contain>
              </v-img>
            </v-flex>
            <v-flex xs9 sm10 md8 align-self-center>
              <span
                style="
                  font-family: poppinsbold;
                  font-size: 14px;
                  cursor: pointer;
                  color: black;
                "
              >
                Management Information 
                <br />
                System
              </span>
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
      <v-app-bar-nav-icon
        v-if="appType == 'Client'"
        class="hidden-lg-and-up"
        @click.native="sideNav2 = !sideNav2"
      >
        <v-icon color="#ff0000">mdi-account</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-app-bar
      color="white"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap fill-height>
        <v-flex xs1>
          <!-- <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
            <v-icon color="#ff0000">mdi-menu</v-icon>
          </v-app-bar-nav-icon> -->
          <router-link to="/">
            <!-- <span
              style="
                font-family: poppinsbold;
                font-size: 20px;
                cursor: pointer;
                color: #545454;
              "
            >
              Paramabikulam Tiger Reserve
            </span> -->
            <v-img src="./../../assets/wayanadLogo.png" height="60px" contain>
            </v-img>
          </router-link>
        </v-flex>

        <v-flex xs10 sm7 md10 align-self-center>
          <span
            style="
              font-family: poppinsbold;
              font-size: 19px;
              cursor: pointer;
              color: black;
            "
          >
            Management Information System
          </span>
        </v-flex>
        <v-spacer></v-spacer>
        <!-- <v-flex xs2 lg2 xl1 align-self-center>
          <v-form @submit.prevent="appSearch">
            <v-text-field
              class="searchBox"
              v-model="searchKey"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search for Hives"
              solo
              flat
              hide-details
              color="#FF0F0F"
            ></v-text-field>
          </v-form>
        </v-flex> -->
        <v-flex xs6 lg1 xl1 px-4 text-center align-self-center>
          <AccountMenu />
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
import store from "../../store";
import AccountMenu from "./accountMenu";
export default {
  components: {
    AccountMenu,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: false,
      sideNav2: false,
      user: {},

      dash: [
        { name: "DASHBOARD", route: "/Admin" },

        { name: "ANALYTICS", route: "/Analytics" },
        { name: "HOLIDAYS", route: "/Admin/holidays" },
      ],
      reports: [
        { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        {
          name: "Reservation List",
          route: "/Admin/ReservationList/dateWisePrograms",
        },
        { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        { name: "Program Report", route: "/Admin/bookingreport" },
        {
          name: "Reports",
          route: "/Transactions",
        },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        { name: "Guest List", route: "/Admin/guestList" },
        { name: "User List", route: "/Admin/userList" },
        { name: "User Report", route: "/Admin/UserReport" },
        { name: "Consolidated User", route: "/Admin/UserConsolidate" },
      ],

      admin: [{ name: "ADMINS", route: "/Admins" }],
      programs: [
        { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "ADD PROGRAM", route: "/Admin/Programs/Add" },
        { name: "CATEGORY", route: "/Admin/category" },
      ],
      accommodations: [
        { name: "ACCOMODATION", route: "/Admin/cottage" },
        { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
      ],
      products: [
        { name: "PRODUCTS", route: "/products" },
        { name: "ADD PRODUCT", route: "/addProduct" },
      ],
      website: [
        { name: "Notification", route: "/Admin/notifications" },
        { name: "Home Slider", route: "/homeSlider" },
        { name: "Static", route: "/viewStatic" },
      ],

      navItems: [
        { name: "DASHBOARD", route: "/Admin" },
        { name: "ANALYTICS", route: "/Analytics" },
        { name: "ADMINS", route: "/Admins" },
        // { name: "ADD OFFICES", route: "/Admin/AddOffices" },
        { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "PRODUCTS", route: "/products" },
        { name: "ACCOMODATION", route: "/Admin/cottage" },
        { name: "ADD PROGRAMS", route: "/Admin/Programs/Add" },
        { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
        { name: "HOLIDAYS", route: "/Admin/holidays" },
        { name: "CATEGORY", route: "/Admin/category" },
        { name: "Get Involved", route: "/Admin/getInvolved" },
        { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        {
          name: "Reservation List",
          route: "/Admin/reservation?dateProp=dateWisePrograms",
        },
        { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        { name: "Guest List", route: "/Admin/guestList" },
        { name: "User List", route: "/Admin/userList" },
        { name: "User Report", route: "/Admin/UserReport" },
        { name: "Consolidated User", route: "/Admin/UserConsolidate" },
        { name: "Program Report", route: "/Admin/bookingreport" },
        {
          name: "Reports",
          route: "/Transactions",
        },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        {
          name: "Learn About The Reserve",
          route: "/learnAboutTheReserve",
        },
        {
          name: "Page Contributor",
          route: "/Admin/staticComponent/Page Contributors",
        },
        {
          name: "Travel and Cancellation Policy",
          route: "/Admin/staticComponent/Travel and Cancellation Policy",
        },
        {
          name: "Privacy Policy",
          route: "/Admin/staticComponent/Privacy Policy",
        },

        {
          name: "Terms and Conditions",
          route: "/Admin/staticComponent/Terms and Conditions",
        },
        {
          name: "Indemnity Bond",
          route: "/Admin/staticComponent/Indemnity Bond",
        },
        { name: "Notifications", route: "/Admin/notifications" },
        { name: "Home Slider", route: "/Admin/homeSlider" },
      ],
      subAdminNavItems: [
        { name: "DASHBOARD", route: "/Admin" },
        { name: "ANALYTICS", route: "/Analytics" },
        // { name: "ADD OFFICES", route: "/Admin/AddOffices" },
        { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "PRODUCTS", route: "/products" },
        { name: "ACCOMODATION", route: "/Admin/cottage" },
        { name: "ADD PROGRAMS", route: "/Admin/Programs/Add" },
        { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
        { name: "HOLIDAYS", route: "/Admin/holidays" },
        { name: "CATEGORY", route: "/Admin/category" },
        { name: "Get Involved", route: "/Admin/getInvolved" },
        { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        {
          name: "Reservation List",
          route: "/Admin/reservation?dateProp=dateWisePrograms",
        },
        { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        { name: "Guest List", route: "/Admin/guestList" },
        { name: "User List", route: "/Admin/userList" },
        { name: "User Report", route: "/Admin/UserReport" },
        { name: "Consolidated User", route: "/Admin/UserConsolidate" },
        { name: "Program Report", route: "/Admin/bookingreport" },

        {
          name: "Reports",
          route: "/Transactions",
        },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        {
          name: "Learn About The Reserve",
          route: "/learnAboutTheReserve",
        },
        {
          name: "Page Contributor",
          route: "/Admin/staticComponent/Page Contributors",
        },
        {
          name: "Travel and Cancellation Policy",
          route: "/Admin/staticComponent/Travel and Cancellation Policy",
        },
        {
          name: "Privacy Policy",
          route: "/Admin/staticComponent/Privacy Policy",
        },

        {
          name: "Terms and Conditions",
          route: "/Admin/staticComponent/Terms and Conditions",
        },
        {
          name: "Indemnity Bond",
          route: "/Admin/staticComponent/Indemnity Bond",
        },
        { name: "Notifications", route: "/Admin/notifications" },
        { name: "Home Slider", route: "/Admin/homeSlider" },
      ],
      productAdminNavItems: [
        // { name: "DASHBOARD", route: "/Admin" },
        // { name: "ADMINS", route: "/Admins" },
        // { name: "ADD OFFICES", route: "/Admin/AddOffices" },
        // { name: "PROGRAMS", route: "/Admin/Programs" },
        // { name: "ACCOMODATION", route: "/Admin/cottage" },
        // { name: "ADD PROGRAMS", route: "/Admin/Programs/Add" },
        // { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
        // { name: "HOLIDAYS", route: "/Admin/holidays" },
        // { name: "CATEGORY", route: "/Admin/category" },
        // { name: "Get Involved", route: "/Admin/getInvolved" },
        // { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        // { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        // { name: "Guest List", route: "/Admin/guestList" },
        // { name: "User List", route: "/Admin/userList" },
        //    { name: "Program Report", route: "/Admin/bookingreport" },
        { name: "PRODUCTS", route: "/products" },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        //  {
        //     name: "Learn About The Reserve",
        //     route: "/learnAboutTheReserve",
        //   },
      ],
      categories: [],
      stylCategories: [],
      userName: null,
      profilePic: null,
      cartItems: 0,
      searchKey: null,
    };
  },
  beforeMount() {
    this.searchKey = this.$route.query.searchKey;
  },
  computed: {
    appUser() {
      return store.state.userData;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return store.state.menus;
    },
    appLogin() {
      return store.state.isLoggedIn;
    },
    appType() {
      return store.state.userType;
    },
    appNavItems() {
      if (this.appType == "subadmin") {
        return this.subAdminNavItems;
      } else if (this.appType == "productadmin") {
        return this.productAdminNavItems;
      } else return this.navItems;
    },
  },

  methods: {
    appLogout() {
      store.commit("logoutUser", true);
      if (this.sideNav2 == true) {
        this.sideNav2 = false;
      }
    },
    appSearch() {
      if (this.appType == "Client") {
        if (this.$route.query.searchKey != this.searchKey) {
          this.$router.push({
            path: "/Products",
            query: {
              searchKey: this.searchKey,
            },
          });
        } else {
          return;
        }
      } else {
        if (this.$route.query.searchKey != this.searchKey) {
          this.$router.push({
            path: "/Seller/Inventory",
            query: {
              searchKey: this.searchKey,
            },
          });
        } else {
          return;
        }
      }
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>

